import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => {

  const image = 'https://rfallp.com/images/assets/images/GettyImages-950232884.jpg';

  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Services | Philanthropic Consulting"
          image={image}
          slug={location.pathname}
          description="Our team works with you to develop a comprehensive philanthropic plan so that your wealth will have a substantial and lasting impact."
        />
        {/* Main */}
        <section className="wrapper style1">
          <div className="container">
            <div className="row gtr-200">
              <div className="col-4 col-12-narrower">
                <div id="sidebar">

                  {/* Sidebar */}

                  <section>
                    <h3>Services</h3>
                    <ul className="links">
                      <li><Link to="/services/">Overview</Link></li>
                      <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                      <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                      <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                      <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                      <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                    </ul>
                  </section>

                </div>
              </div>
              <div className="col-8  col-12-narrower imp-narrower">
                <div id="content">

                  {/* Content */}
                  <article>
                    <header>
                      <h2>Philanthropic Consulting</h2>
                      <p>
                        Our team works with you to develop a comprehensive philanthropic plan so that your wealth will have a substantial and lasting impact.
                      </p>
                    </header>

                    <span className="image featured">
                      <StaticImage
                        src="../../assets/images/GettyImages-950232884.jpg"
                        alt=""
                        width={725}
                      />
                    </span>

                    <p>
                    Families of exceptional wealth are increasingly aware of their ability to impact society through the thoughtful stewardship of their wealth for causes they deem worthy. Our professionals have significant experience advising clients in family philanthropy, including:
                    </p>
                    <ul>
                      <li>Structuring charitable vehicles.</li>
                      <li>Foundation management.</li>
                      <li>Personal giving.</li>
                      <li>Charitable tax planning and compliance.</li>
                    </ul>
                    <p>
                    Deploying family capital in the not-for-profit sector through a family foundation, donor advised fund or a structured charitable gift can be an effective way for clients to engage their family members across generations, promote family unity, perpetuate values and create opportunities for family education. Increasingly, we also see client interest in making investments that have a social impact.
                    </p>
                    <p>
                    Rosewood Family Advisors’ Philanthropic Planning capabilities include:
                    </p>
                    <ul>
                      <li>Comprehensive reviews and analysis of a client’s complete charitable giving history.</li>
                      <li>Assisting the family in identifying and articulating the values they wish to perpetuate as a family charitable legacy.</li>
                      <li>Choosing, establishing and providing ongoing administration of philanthropic entities including family foundations, supporting organizations, donor advised funds, charitable lead trusts and charitable remainder trusts.</li>
                      <li>Identification and research of specific charitable organizations that fit the family’s interests and funding priorities.</li>
                      <li>Negotiation of major charitable gifts.</li>
                      <li>Planning and structuring tax-effective philanthropic vehicles to benefit a client’s individual tax, financial and family profile.</li>
                      <li>Record-keeping, accounting and reporting on charitable activities to meet legal and regulatory requirements.</li>
                    </ul>
                    <p>
                    Our team works with you to develop and maintain a comprehensive philanthropic plan and when appropriate coordinates this work with outside advisors, third-party charitable organizations and charitable donees.
                    </p>

                  </article>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InteriorPage;
